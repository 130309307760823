
import overview from './overview.js'
import consent from './consent.js'

export default {

  ...overview,
  ...consent

}
