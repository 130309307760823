// 充值方式
export const RECHARGE_TYPE = {
	Wechat: {
		value: 1,
		desc: '微信'
	},
	Alipay: {
		value: 2,
		desc: '支付宝'
	},
	Bank: {
		value: 3,
		desc: '银行卡'
	}
}
export const USER_TYPE = {
	GEREN: {
		value: 1,
		desc: '个人',
		color: 'F50'
	},
	ZUZHI: {
		value: 2,
		desc: '组织'
	}
}
// 签署状态
export const SIGNATURE_STATUS = {
	WAITING_FOR_REFERENCE: {
		value: 1,
		desc: '待查阅',
    style: 'pro-unstart'
	},
	WAITING_FOR_COMMUNICATION: {
		value: 2,
		desc: '待沟通',
    style: 'pro-unstart'
	},
	ALREADY_CONSULTED: {
		value: 3,
		desc: '待知情',
    style: 'pro-ing'
	},
	WAITING_FOR_SIGNATURE: {
		value: 4,
		desc: '待签署',
    style: 'pro-ing'
	},
	ALREADY_COMPLETED: {
		value: 5,
		desc: '已完成',
    style: 'pro-finish'
	},
  ALREADY_REFUSED: {
		value: 6,
		desc: '已拒绝',
    style: 'pro-unstart'
	}
}
// 签署状态
export const ICF_STATUS = {
	UNKNOW: {
		value: 3,
		desc: '待知情'
	},
  KNOWING: {
		value: 4,
		desc: '待签署'
	},
  KNOWED: {
		value: 5,
		desc: '已完成'
	},
  REFUSED: {
		value: 6,
		desc: '已拒绝'
	},
  SEE: {
		value: 7,
		desc: '已撤回'
	},
  REFULT: {
		value: 8,
		desc: '已拒绝'
	}
}
// 系统日志操作类型
export const ENUMERATION = {
	DAISHENHE: {
		value: 1,
		desc: '增加'
	},
	DAIFUKUAN: {
		value: 2,
		desc: '删除'
	},
	DAIWANSHUI: {
		value: 3,
		desc: '更新'
	},
	BAOSHUIZHONG: {
		value: 4,
		desc: '查询'
	},
	YIWANCHENG: {
		value: 5,
		desc: '登陆验证'
	},
	DAIPISHENHE: {
		value: 3,
		desc: '导入'
	},
	DAIZHIFU: {
		value: 4,
		desc: '导出'
	}
}
// 操作行为
export const OPERATION_BEHAVIOR = {
	OPEN: {
		value: 1,
		desc: '点击开始'
	},
	NEXT: {
		value: 2,
		desc: '点击下一步'
	},
	START: {
		value: 3,
		desc: '点击开始'
	},
	COMPREHENSION: {
		value: 4,
		desc: '点击理解/不理解'
	},
	PLAY: {
		value: 5,
		desc: '点击播放视频/音频'
	},
  STOP: {
		value: 6,
		desc: '点击暂停视频/音频'
	},
  RETURN: {
		value: 7,
		desc: '点击关闭/返回/列表'
	}
}
export const ACTION_TYPE = {
	VIEW: {
		value: 'QUERY',
		desc: '查询'
	},
	ADD: {
		value: 'ADD',
		desc: '新增'
	},
	EDIT: {
		value: 'UPDATE',
		desc: '编辑'
	},
	REMOVE: {
		value: 'DELETE',
		desc: '删除'
	},
	EXPORT: {
		value: 'IMPORT',
		desc: '导出'
	}
}
export default {
	RECHARGE_TYPE,
	SIGNATURE_STATUS,
  ICF_STATUS,
	OPERATION_BEHAVIOR,
	ENUMERATION,
	USER_TYPE,
  ACTION_TYPE
}
