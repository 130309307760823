import request from '@/utils/request'

const userApi = {
  Login: '/boss/pad/pro/login',
  Logout: '/boss/passport/logout',
  UserInfo: '/boss/pad/pro/pmsInfo',
  sendCode: '/boss/pad/pro/sendCode',
  reset: '/boss/pad/pro/reset'
}

export function login (parameter) {
  return request({
    url: userApi.Login,
    method: 'post',
    data: parameter
  })
}

export function getInfo (parameter) {
  return request({
    url: userApi.UserInfo,
    method: 'get',
    params: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function logout () {
  return request({
    url: userApi.Logout,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getSendCode (parameter) {
  return request({
    url: userApi.sendCode,
    method: 'post',
    data: parameter
  })
}

export function getReset (parameter) {
  return request({
    url: userApi.reset,
    method: 'post',
    data: parameter
  })
}

export function selectOperation (parameter) {
  return request({
    url: '/boss/admin/operation/selectOperation',
    method: 'post',
    data: parameter
  })
}

export function firtSign (data) {
  return request({
    url: '/boss/passport/first',
    method: 'post',
    data
  })
}
