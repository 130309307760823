import Vue from 'vue'

// ext library
// import Dialog from '@/components/Dialog'
import PageLoading from '@/components/PageLoading'
import PermissionHelper from '@/core/permission/permission'
import './directives/action'

// Vue.use(Dialog) // this.$dialog func
Vue.use(PageLoading)
Vue.use(PermissionHelper)

process.env.NODE_ENV !== 'production' && console.warn('[antd-pro] NOTICE: Antd use lazy-load.')
