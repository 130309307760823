// eslint-disable-next-line
import { UserLayout, BasicLayout } from '@/layouts'
// import { bxAnaalyse } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

/**
 * 异步路由
 */
export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '首页' },
    redirect: '/overview',
    children: [
      {
        path: '/overview',
        name: 'overview',
        component: RouteView,
        redirect: '/overview/all',
        meta: { title: '项目总览' },
        children: [
          {
            path: '/overview/all',
            name: 'All',
            component: () => import('@/views/overview/index'),
            meta: { title: '全部项目' }
          },
          {
            path: '/overview/detail',
            name: 'Detail',
            component: () => import('@/views/overview/detail'),
            meta: { title: '项目详情', keepAlive: true }
          }
        ]
      },
      {
        path: '/consent/detail',
        name: 'ConsentDetail',
        component: () => import('@/views/consent/detail'),
        meta: { title: '签署详情' }
      },
      {
        path: '/consent/preview',
        name: 'ConsentPreview',
        component: () => import('@/views/consent/preview'),
        meta: { title: '知情预览' }
      },
      {
        path: '/consent/sign',
        name: 'ConsentSign',
        component: () => import('@/views/consent/sign'),
        meta: { title: '签署文件' }
      },
      {
        path: '/consent/file',
        name: 'file',
        component: () => import('@/views/consent/fileSee'),
        meta: { title: '签署文件查看' }
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [

  {
    path: '/user/login',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login'),
    name: 'login',
    hidden: true
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/privacy'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
