<template>
  <a-modal
    title="重置密码"
    width="600px"
    :visible="modalVisible"
    :closable="false"
    :maskClosable="false"
  >
    <div v-if="flag">
      <div class="notice" v-if="flag==0"><a-icon type="exclamation" />首次登录，为了您的信息安全请重置密码</div>
      <div class="notice notice-color" v-if="flag==2"><a-icon type="info" />您已超过 90 天未修改密码，为了账号安全请修改密码</div>
    </div>
    <a-form-model
      :model="form"
      :rules="formRules"
      ref="formRef"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="请输入新密码" prop="pwd">
        <a-input-password v-model="form.pwd" :maxLength="30" placeholder="至少8位包含数字、大小写英文、特殊符号(“!@#$%^&*.?”)" />
      </a-form-model-item>
      <a-form-model-item label="确认新密码" prop="pwdConfirm">
        <a-input-password v-model="form.pwdConfirm" :maxLength="30" placeholder="至少8位包含数字、大小写英文、特殊符号(“!@#$%^&*.?”)" />
      </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <a-button key="back" @click="handleCancel" v-if="!flag">
        取消
      </a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
        确定
      </a-button>
    </template>
  </a-modal>
</template>
<script>
import store from '@/store'
import { updatePassword } from '@/api/account/user'

export default {
  name: 'SetPwd',
  data () {
    const validatorConfirmPwd = (rule, value, callback) => {
      if (this.form.pwd === this.form.pwdConfirm) {
        callback()
      } else {
        callback(new Error('两次密码不一致！'))
      }
    }
    return {
      modalVisible: false,
      form: {
        pwd: '',
        pwdConfirm: ''
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      formRules: {
        pwd: [
          { required: true, message: '请输入密码', trigger: 'change' },
          { pattern: /^\S*(?=\S{8,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*.? ])\S*$/, message: '至少8位包含数字、大小写英文、特殊符号(“!@#$%^&*.?”)' }
        ],
        pwdConfirm: [
          { required: true, message: '请输入确认新密码', trigger: 'change' },
          { pattern: /^\S*(?=\S{8,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*.? ])\S*$/, message: '至少8位包含数字、大小写英文、特殊符号(“!@#$%^&*.?”)' },
          { required: true, trigger: 'blur', validator: validatorConfirmPwd }
        ]
      },
      loading: false,
      flag: null
    }
  },
  methods: {
    open (e) {
      this.modalVisible = true
      if (e) {
        this.flag = e
      } else {
        this.flag = null
      }
    },
    handleOk () {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.updatePassword()
        }
      })
    },
    updatePassword () {
      this.loading = true
      updatePassword({ id: store.getters.userInfo.id, pwd: this.form.pwd, pad: true })
        .then(res => {
          this.$message.success('密码修改成功')
          this.loading = false
          this.handleCancel()
          localStorage.removeItem('isRestPwd')
          if (this.flag === 0 || this.flag === 2) {
            // console.log('首次登录或超过90天修改密码')
          } else {
            // 修改密码成功 退出登录
            this.$store.dispatch('Logout').then(() => {
              this.$router.push({ name: 'login' })
            })
          }
        })
        .catch(() => {})
    },
    // 点击取消关闭对话框并重置
    handleCancel () {
      this.modalVisible = false
      setTimeout(() => {
        this.$refs.formRef.resetFields()
      }, 200)
    }
  }
}
</script>

<style lang="less" scoped>
.notice{
  width: 100%;
  background: #F1F8FF;
  padding: 8px 10px;
  margin-bottom: 20px;
  .anticon {
    width: 16px;
    height: 16px;
    text-align: center;
    background: #3B82F6;
    color: #fff;
    border-radius: 50%;
    font-size: 16px;
    margin-right: 10px;
  }
}
.notice-color {
  background: #FFF9EE;
  .anticon {
    background: #FFAD0D;
  }
}
</style>
