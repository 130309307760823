// 所属中心状态
export const PTS_STATUS = {
  DAISHAIXUAN: {
    value: 1,
    desc: '待筛选'
  },
  SHAIXUANZHONG: {
    value: 2,
    desc: '筛选中'
  },
  SHAIXUANSHIBAI: {
    value: 3,
    desc: '筛选失败'
  },
  SUIJI: {
    value: 4,
    desc: '随机'
  },
  ZHILIAOZHONG: {
    value: 5,
    desc: '治疗中'
  },
  TUICHU: {
    value: 6,
    desc: '退出'
  },
  ZHILIAOJIESHU: {
    value: 7,
    desc: '治疗结束'
  },
  ANQUANXINGSUIFANG: {
    value: 8,
    desc: '安全性随访'
  },
  SHENGCUNSUIFANG: {
    value: 9,
    desc: '生存随访'
  }
}
// 研究分期
export const SPONSOR_STAGE = {
	FIRST_STAGE: {
		value: 1,
		desc: 'I期'
	},
	SECOND_STAGE: {
		value: 2,
		desc: 'II期'
	},
	THIRD: {
		value: 3,
		desc: 'III期'
	},
	FOURTH_STAGE: {
		value: 4,
		desc: 'IV期'
	},
	OTHERS: {
		value: 5,
		desc: '其他'
	},
	BE: {
		value: 6,
		desc: 'BE'
	}
}
// 项目状态
export const PROJECT_STATUS = {
	NOT_START: {
		value: 1,
		desc: '未启动',
    style: 'pro-unstart'
	},
	ON_GOING: {
		value: 2,
		desc: '进行中',
    style: 'pro-ing'
	},
	CLOSED: {
		value: 3,
		desc: '已关闭',
    style: 'pro-finish'
	}
}
// 知情文件状态
export const CONTRACT_STATUS = {
	CLOSED: {
		value: 0,
		desc: '停用'
	},
	START: {
		value: 1,
		desc: '启用'
	}
}
// 邀请状态（团队成员）
export const INVITATION_STATUS = {
  INVITED: {
    value: 1,
    desc: '已邀请'
  },
  JOINED: {
    value: 2,
    desc: '已加入'
  }
}
// 研究中心状态
export const RESEARCH_CENTER_STATUS = {
  ACTIVE: {
    value: 1,
    desc: '活跃'
  },
  INACTIVE: {
    value: 2,
    desc: '不活跃'
  }
}
// 研究中心邀请成员状态
export const CENTER_MEMBER_STATUS = {
  SUBJECT: {
    value: 1,
    desc: '接受'
  },
  REJECT: {
    value: 2,
    desc: '拒绝'
  },
  WAITING: {
    value: 3,
    desc: '待处理'
  },
  JOIN: {
    value: 4,
    desc: '待加入'
  }
}

export default {
	SPONSOR_STAGE,
	PROJECT_STATUS,
	CONTRACT_STATUS,
	INVITATION_STATUS,
  RESEARCH_CENTER_STATUS,
  CENTER_MEMBER_STATUS,
	PTS_STATUS
}
