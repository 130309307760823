export function getBrowserInfo () {
  const agent = navigator.userAgent.toLowerCase()
  // console.log(agent)
  // let system = agent.split(' ')[1].split(' ')[0].split('(')[1];
  const REGSTR_EDGE = /edge\/[\d.]+/gi
  const REGSTR_IE = /trident\/[\d.]+/gi
  const OLD_IE = /msie\s[\d.]+/gi
  const REGSTR_FF = /firefox\/[\d.]+/gi
  const REGSTR_CHROME = /chrome\/[\d.]+/gi
  const REGSTR_SAF = /safari\/[\d.]+/gi
  const REGSTR_OPERA = /opr\/[\d.]+/gi
  const REGSTR_QQ = /qqbrowser\/[\d.]+/gi
  // let REGSTR_METASR = /metasr\+/gi
  const REGSTR_WECHAT = /MicroMessenger\/[\d.]+/gi

  if (agent.indexOf('trident') > 0) {
    // IE
    return agent.match(REGSTR_IE)[0].split('/')[0] + ' ' + agent.match(REGSTR_IE)[0].split('/')[1]
  } else if (agent.indexOf('msie') > 0) {
    // OLD_IE
    return agent.match(OLD_IE)[0].split('/')[0] + ' ' + agent.match(OLD_IE)[0].split('/')[1]
  } else if (agent.indexOf('edge') > 0) {
    // Edge
    return agent.match(REGSTR_EDGE)[0].split('/')[0] + ' ' + agent.match(REGSTR_EDGE)[0].split('/')[1]
  } else if (agent.indexOf('firefox') > 0) {
    // firefox
    return agent.match(REGSTR_FF)[0].split('/')[0] + ' ' + agent.match(REGSTR_FF)[0].split('/')[1]
  } else if (agent.indexOf('opr') > 0) {
    // Opera
    return agent.match(REGSTR_OPERA)[0].split('/')[0] + ' ' + agent.match(REGSTR_OPERA)[0].split('/')[1]
  } else if (agent.indexOf('safari') > 0 && agent.indexOf('chrome') < 0) {
    // Safari
    return agent.match(REGSTR_SAF)[0].split('/')[0] + ' ' + agent.match(REGSTR_SAF)[0].split('/')[1]
  } else if (agent.indexOf('qqbrowse') > 0) {
    // qqbrowse
    return agent.match(REGSTR_QQ)[0].split('/')[0] + ' ' + agent.match(REGSTR_QQ)[0].split('/')[1]
  } else if (agent.indexOf('metasr') > 0) {
    // metasr火狐
    return 'metasr'
  } else if (agent.indexOf('micromessenger') > 0) {
    // 微信内置浏览器
    return agent.match(REGSTR_WECHAT)[0].split('/')[0] + ' ' + agent.match(REGSTR_WECHAT)[0].split('/')[1]
  } else if (agent.indexOf('chrome') > 0) {
    // Chrome
    return agent.match(REGSTR_CHROME)[0].split('/')[0] + ' ' + agent.match(REGSTR_CHROME)[0].split('/')[1]
  } else {
    return '未获取到浏览器信息'
  }
}
