<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <keep-alive v-if="isLogin">
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive || !isLogin" />

    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'
import { ACCESS_TOKEN } from '@/store/mutation-types'
export default {
  data () {
    return {
      isLogin: false
    }
  },
  watch: {
    $route (to, from) {
      this.isLogin = !!localStorage.getItem(ACCESS_TOKEN)
      console.log(this.$route.meta, '是否登录', this.isLogin)
    }
  },
  computed: {
    locale () {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && (setDocumentTitle(`${i18nRender(title)} - ${domTitle}`))

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  }
}
</script>
