import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
// import store from '@/store'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  if (!dataStr) return '-'
  return moment(dataStr).format(pattern)
})

// 常量转换
Vue.filter('enumValueByKey', function (value, enumObj, key = 'desc') {
  var target = ''
  Object.keys(enumObj).forEach(item => {
    const obj = enumObj[item]
    if (obj.value === value) {
      target = obj[key]
    }
  })
  return target
})

// 格式化图片地址
Vue.filter('dealImageUrl', function (url) {
  if (!url) return ''
  return (url.substr(0, 4) === 'http' || url.substr(0, 2) === '//') ? url : process.env.VUE_APP_BASE_IMAGE + url
})

// 将视频时长转为时分秒
Vue.filter('formatVideo', function (value, type = 0) {
  if (type === 1) {
    value = value / 1000
  }
  let hour = 0
  let minute = 0
  let second = 0
  let res = ''
  if (value) {
    hour = parseInt(value / 3600)
    minute = parseInt((value % 3600) / 60)
    second = value % 60
  }
  res = hour + '时' + minute + '分' + String(second).padStart(2, '0') + '秒'
  return res
})

// 校验去除中文
Vue.filter('checkUnChinese', function (val) {
  val = val.replace(/[\u4E00-\u9FA5]/g, '')
  return val
})

// 判断参与者是否可见
Vue.filter('isPtsVisible', function () {
  // const curRoles = store.getters.roles
  // let flag = false
  // if (curRoles && curRoles.length) {
  //   if (curRoles[0].ptsVisible === 1) {
  //     flag = true
  //   }
  // }
  // return flag
  return true
})
